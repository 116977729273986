.global-sidebar {
    /* background-color: var(--sidebar-background); */
    background-color: black;
    float: left;
    height: 100vh;
    padding: 20px 18px 18px 16px;
    position: fixed;
    width: 262px;
    z-index: 1;
}

@media screen and (min-width: 1600px) {
    .global-sidebar {
        width: 291px;
    }
}

@media screen and (min-width: 1700px) {
    .global-sidebar {
        width: 310px;
    }
}

@media screen and (min-width: 1800px) {
    .global-sidebar {
        width: 330px;
    }
}

@media screen and (min-width: 1900px) {
    .global-sidebar {
        width: 350px;
    }
}

.global-sidebar .main-div {
    position: relative;
    height: -webkit-fill-available;
}

.global-sidebar .sidemenu .btn {
    align-items: center;
    color: #fff;
    display: inline-flex;
    font-size: 14px;
    font-weight: 300;
    opacity: .6;
    padding: 7px;
    text-align: left;
    text-decoration: none;
    width: 100%;
}

.global-sidebar .sidemenu .sidemenu-content{
    margin-left: -28px;
}

.global-sidebar .sidemenu .sidemenu-content li{
    list-style-type: none;
    align-items: center;
}

.global-sidebar .sidemenu .sidemenu-content li span{
    margin-left: 34px;
}


.global-sidebar .sidemenu .sidemenu-content .btn-link{
    font-size: 15px;
    color: #fff!important;
    font-weight: 400;
    opacity: 1;
}

.global-sidebar .sidemenu .sidemenu-content .active-btn{
    background-color: #176b41;
    color: #fff!important;
    font-weight: 500;
    opacity: 1;
}

.logout {
    border-radius: 0;
    font-weight: 400;
    padding-top: 18px;
    position: absolute;
    bottom: 10px;
}

.logout span {
    color: white;
    text-decoration: none;
    margin-left: 5px;
}

.logout span {
    margin-left: 34px;
}

.logout .btn-link {
    text-decoration: none;
}

.logout .btn-link:hover {
    background-color: black;
}

.sidebar .right-table {
    /* padding: 46px; */
    padding: 10px 10px 10px 46px;
}

.sidebar .main-header {
    margin: 10px 10px 10px 46px;
    padding: 12px 16px 12px 16px;
    display: flex;
}

.sidebar .main-header .a-hadding {
    font-size: 20px;
    font-weight: 700;
    text-decoration: none;
    color: black;
}

.transactions-list .has-table .auto-load-table .table {
    border-collapse: collapse;
}

.transactions-list .has-table .auto-load-table .table tbody tr {
    position: relative;
}

.transactions-list .has-table .auto-load-table .table td:hover {
    cursor: default;
}

.transactions-list .has-table .auto-load-table .table td:hover .row-highlighted {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: #176B41;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}